import { FC, PropsWithChildren } from "react"

interface Props extends PropsWithChildren {
  className?: string
}

const Container: FC<Props> = ({ children, className }) => {
  return <main className={`mx-auto max-w-7xl px-4  ${className}`}>{children}</main>
}

export default Container
