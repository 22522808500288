/* tslint:disable */
/* eslint-disable */
/**
 * Poznejme Cesko API
 * Poznejme Cesko API Documentation
 *
 * OpenAPI spec version: v1.0.0
 * Contact: jan.fara@cleevio.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { BuyPartnerProductRequest } from '../models';
import { BuyPartnerProductResult } from '../models';
import { GetPlayerPartnerProductsQueryResult } from '../models';
import { PageGetPartnerProductsForMarketQueryResult } from '../models';
import { UsePartnerProductRequest } from '../models';
/**
 * PlayerPartnerProductApi - axios parameter creator
 * @export
 */
export const PlayerPartnerProductApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Player buy partner product
         * @param {BuyPartnerProductRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyPartnerProduct: async (body: BuyPartnerProductRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling buyPartnerProduct.');
            }
            const localVarPath = `/players/me/partner-products/buy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            // authentication x-platform required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Platform")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Platform"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get partner products available for market
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerProductsForMarket: async (page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/players/me/partner-products/market`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            // authentication x-platform required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Platform")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Platform"] = localVarApiKeyValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get player's partner products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlayerPartnerProducts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/players/me/partner-products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            // authentication x-platform required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Platform")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Platform"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Player use partner product
         * @param {UsePartnerProductRequest} body 
         * @param {string} partnerProductId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usePartnerProduct: async (body: UsePartnerProductRequest, partnerProductId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling usePartnerProduct.');
            }
            // verify required parameter 'partnerProductId' is not null or undefined
            if (partnerProductId === null || partnerProductId === undefined) {
                throw new RequiredError('partnerProductId','Required parameter partnerProductId was null or undefined when calling usePartnerProduct.');
            }
            const localVarPath = `/players/me/partner-products/{partnerProductId}/use`
                .replace(`{${"partnerProductId"}}`, encodeURIComponent(String(partnerProductId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            // authentication x-platform required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Platform")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Platform"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlayerPartnerProductApi - functional programming interface
 * @export
 */
export const PlayerPartnerProductApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Player buy partner product
         * @param {BuyPartnerProductRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyPartnerProduct(body: BuyPartnerProductRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<BuyPartnerProductResult>>> {
            const localVarAxiosArgs = await PlayerPartnerProductApiAxiosParamCreator(configuration).buyPartnerProduct(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get partner products available for market
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPartnerProductsForMarket(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<PageGetPartnerProductsForMarketQueryResult>>> {
            const localVarAxiosArgs = await PlayerPartnerProductApiAxiosParamCreator(configuration).getPartnerProductsForMarket(page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get player's partner products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlayerPartnerProducts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<GetPlayerPartnerProductsQueryResult>>>> {
            const localVarAxiosArgs = await PlayerPartnerProductApiAxiosParamCreator(configuration).getPlayerPartnerProducts(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Player use partner product
         * @param {UsePartnerProductRequest} body 
         * @param {string} partnerProductId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usePartnerProduct(body: UsePartnerProductRequest, partnerProductId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await PlayerPartnerProductApiAxiosParamCreator(configuration).usePartnerProduct(body, partnerProductId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PlayerPartnerProductApi - factory interface
 * @export
 */
export const PlayerPartnerProductApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Player buy partner product
         * @param {BuyPartnerProductRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyPartnerProduct(body: BuyPartnerProductRequest, options?: AxiosRequestConfig): Promise<AxiosResponse<BuyPartnerProductResult>> {
            return PlayerPartnerProductApiFp(configuration).buyPartnerProduct(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get partner products available for market
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPartnerProductsForMarket(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<AxiosResponse<PageGetPartnerProductsForMarketQueryResult>> {
            return PlayerPartnerProductApiFp(configuration).getPartnerProductsForMarket(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get player's partner products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlayerPartnerProducts(options?: AxiosRequestConfig): Promise<AxiosResponse<Array<GetPlayerPartnerProductsQueryResult>>> {
            return PlayerPartnerProductApiFp(configuration).getPlayerPartnerProducts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Player use partner product
         * @param {UsePartnerProductRequest} body 
         * @param {string} partnerProductId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usePartnerProduct(body: UsePartnerProductRequest, partnerProductId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return PlayerPartnerProductApiFp(configuration).usePartnerProduct(body, partnerProductId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PlayerPartnerProductApi - object-oriented interface
 * @export
 * @class PlayerPartnerProductApi
 * @extends {BaseAPI}
 */
export class PlayerPartnerProductApi extends BaseAPI {
    /**
     * 
     * @summary Player buy partner product
     * @param {BuyPartnerProductRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerPartnerProductApi
     */
    public async buyPartnerProduct(body: BuyPartnerProductRequest, options?: AxiosRequestConfig) : Promise<AxiosResponse<BuyPartnerProductResult>> {
        return PlayerPartnerProductApiFp(this.configuration).buyPartnerProduct(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get partner products available for market
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerPartnerProductApi
     */
    public async getPartnerProductsForMarket(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) : Promise<AxiosResponse<PageGetPartnerProductsForMarketQueryResult>> {
        return PlayerPartnerProductApiFp(this.configuration).getPartnerProductsForMarket(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get player's partner products
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerPartnerProductApi
     */
    public async getPlayerPartnerProducts(options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<GetPlayerPartnerProductsQueryResult>>> {
        return PlayerPartnerProductApiFp(this.configuration).getPlayerPartnerProducts(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Player use partner product
     * @param {UsePartnerProductRequest} body 
     * @param {string} partnerProductId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerPartnerProductApi
     */
    public async usePartnerProduct(body: UsePartnerProductRequest, partnerProductId: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return PlayerPartnerProductApiFp(this.configuration).usePartnerProduct(body, partnerProductId, options).then((request) => request(this.axios, this.basePath));
    }
}
