/* tslint:disable */
/* eslint-disable */
/**
 * Poznejme Cesko API
 * Poznejme Cesko API Documentation
 *
 * OpenAPI spec version: v1.0.0
 * Contact: jan.fara@cleevio.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
/**
 * PlayerLocationApi - axios parameter creator
 * @export
 */
export const PlayerLocationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Confirm comic location by player
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmLocation: async (locationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            if (locationId === null || locationId === undefined) {
                throw new RequiredError('locationId','Required parameter locationId was null or undefined when calling confirmLocation.');
            }
            const localVarPath = `/players/me/locations/{locationId}/confirm`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            // authentication x-platform required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Platform")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Platform"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlayerLocationApi - functional programming interface
 * @export
 */
export const PlayerLocationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Confirm comic location by player
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmLocation(locationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await PlayerLocationApiAxiosParamCreator(configuration).confirmLocation(locationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PlayerLocationApi - factory interface
 * @export
 */
export const PlayerLocationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Confirm comic location by player
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmLocation(locationId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return PlayerLocationApiFp(configuration).confirmLocation(locationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PlayerLocationApi - object-oriented interface
 * @export
 * @class PlayerLocationApi
 * @extends {BaseAPI}
 */
export class PlayerLocationApi extends BaseAPI {
    /**
     * 
     * @summary Confirm comic location by player
     * @param {string} locationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerLocationApi
     */
    public async confirmLocation(locationId: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return PlayerLocationApiFp(this.configuration).confirmLocation(locationId, options).then((request) => request(this.axios, this.basePath));
    }
}
