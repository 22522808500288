/* tslint:disable */
/* eslint-disable */
/**
 * Poznejme Cesko API
 * Poznejme Cesko API Documentation
 *
 * OpenAPI spec version: v1.0.0
 * Contact: jan.fara@cleevio.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { CreatePlayerRoomItemRequest } from '../models';
import { FindAllPlayerRoomResult } from '../models';
import { GetPlayerRoomResult } from '../models';
/**
 * PlayerRoomApi - axios parameter creator
 * @export
 */
export const PlayerRoomApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Place an item to manor's room
         * @param {CreatePlayerRoomItemRequest} body 
         * @param {string} roomId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPlayerRoomItem: async (body: CreatePlayerRoomItemRequest, roomId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createPlayerRoomItem.');
            }
            // verify required parameter 'roomId' is not null or undefined
            if (roomId === null || roomId === undefined) {
                throw new RequiredError('roomId','Required parameter roomId was null or undefined when calling createPlayerRoomItem.');
            }
            const localVarPath = `/players/me/rooms/{roomId}`
                .replace(`{${"roomId"}}`, encodeURIComponent(String(roomId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            // authentication x-platform required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Platform")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Platform"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} playerRoomItemId 
         * @param {string} roomId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePlayerRoomItem: async (playerRoomItemId: string, roomId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'playerRoomItemId' is not null or undefined
            if (playerRoomItemId === null || playerRoomItemId === undefined) {
                throw new RequiredError('playerRoomItemId','Required parameter playerRoomItemId was null or undefined when calling deletePlayerRoomItem.');
            }
            // verify required parameter 'roomId' is not null or undefined
            if (roomId === null || roomId === undefined) {
                throw new RequiredError('roomId','Required parameter roomId was null or undefined when calling deletePlayerRoomItem.');
            }
            const localVarPath = `/players/me/rooms/{roomId}/items/{playerRoomItemId}`
                .replace(`{${"playerRoomItemId"}}`, encodeURIComponent(String(playerRoomItemId)))
                .replace(`{${"roomId"}}`, encodeURIComponent(String(roomId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            // authentication x-platform required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Platform")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Platform"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of rooms for manor
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllRooms: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/players/me/rooms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            // authentication x-platform required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Platform")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Platform"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get manor's room detail
         * @param {string} roomId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlayerRoom: async (roomId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roomId' is not null or undefined
            if (roomId === null || roomId === undefined) {
                throw new RequiredError('roomId','Required parameter roomId was null or undefined when calling getPlayerRoom.');
            }
            const localVarPath = `/players/me/rooms/{roomId}`
                .replace(`{${"roomId"}}`, encodeURIComponent(String(roomId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            // authentication x-platform required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Platform")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Platform"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Select player's main room
         * @param {string} roomId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectRoom: async (roomId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roomId' is not null or undefined
            if (roomId === null || roomId === undefined) {
                throw new RequiredError('roomId','Required parameter roomId was null or undefined when calling selectRoom.');
            }
            const localVarPath = `/players/me/rooms/{roomId}/select`
                .replace(`{${"roomId"}}`, encodeURIComponent(String(roomId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            // authentication x-platform required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Platform")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Platform"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlayerRoomApi - functional programming interface
 * @export
 */
export const PlayerRoomApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Place an item to manor's room
         * @param {CreatePlayerRoomItemRequest} body 
         * @param {string} roomId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPlayerRoomItem(body: CreatePlayerRoomItemRequest, roomId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await PlayerRoomApiAxiosParamCreator(configuration).createPlayerRoomItem(body, roomId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} playerRoomItemId 
         * @param {string} roomId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePlayerRoomItem(playerRoomItemId: string, roomId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await PlayerRoomApiAxiosParamCreator(configuration).deletePlayerRoomItem(playerRoomItemId, roomId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary List of rooms for manor
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllRooms(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<FindAllPlayerRoomResult>>>> {
            const localVarAxiosArgs = await PlayerRoomApiAxiosParamCreator(configuration).findAllRooms(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get manor's room detail
         * @param {string} roomId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlayerRoom(roomId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<GetPlayerRoomResult>>> {
            const localVarAxiosArgs = await PlayerRoomApiAxiosParamCreator(configuration).getPlayerRoom(roomId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Select player's main room
         * @param {string} roomId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async selectRoom(roomId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await PlayerRoomApiAxiosParamCreator(configuration).selectRoom(roomId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PlayerRoomApi - factory interface
 * @export
 */
export const PlayerRoomApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Place an item to manor's room
         * @param {CreatePlayerRoomItemRequest} body 
         * @param {string} roomId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPlayerRoomItem(body: CreatePlayerRoomItemRequest, roomId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return PlayerRoomApiFp(configuration).createPlayerRoomItem(body, roomId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} playerRoomItemId 
         * @param {string} roomId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePlayerRoomItem(playerRoomItemId: string, roomId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return PlayerRoomApiFp(configuration).deletePlayerRoomItem(playerRoomItemId, roomId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of rooms for manor
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllRooms(options?: AxiosRequestConfig): Promise<AxiosResponse<Array<FindAllPlayerRoomResult>>> {
            return PlayerRoomApiFp(configuration).findAllRooms(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get manor's room detail
         * @param {string} roomId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlayerRoom(roomId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<GetPlayerRoomResult>> {
            return PlayerRoomApiFp(configuration).getPlayerRoom(roomId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Select player's main room
         * @param {string} roomId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async selectRoom(roomId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return PlayerRoomApiFp(configuration).selectRoom(roomId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PlayerRoomApi - object-oriented interface
 * @export
 * @class PlayerRoomApi
 * @extends {BaseAPI}
 */
export class PlayerRoomApi extends BaseAPI {
    /**
     * 
     * @summary Place an item to manor's room
     * @param {CreatePlayerRoomItemRequest} body 
     * @param {string} roomId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerRoomApi
     */
    public async createPlayerRoomItem(body: CreatePlayerRoomItemRequest, roomId: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return PlayerRoomApiFp(this.configuration).createPlayerRoomItem(body, roomId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} playerRoomItemId 
     * @param {string} roomId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerRoomApi
     */
    public async deletePlayerRoomItem(playerRoomItemId: string, roomId: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return PlayerRoomApiFp(this.configuration).deletePlayerRoomItem(playerRoomItemId, roomId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary List of rooms for manor
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerRoomApi
     */
    public async findAllRooms(options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<FindAllPlayerRoomResult>>> {
        return PlayerRoomApiFp(this.configuration).findAllRooms(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get manor's room detail
     * @param {string} roomId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerRoomApi
     */
    public async getPlayerRoom(roomId: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<GetPlayerRoomResult>> {
        return PlayerRoomApiFp(this.configuration).getPlayerRoom(roomId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Select player's main room
     * @param {string} roomId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerRoomApi
     */
    public async selectRoom(roomId: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return PlayerRoomApiFp(this.configuration).selectRoom(roomId, options).then((request) => request(this.axios, this.basePath));
    }
}
