import { loginAnonymously } from "@/api/auth/firebase"
import { useRegisterPlayer } from "@/api/auth/hooks"
import AppLoader from "@/components/shared/AppLoader"
import { useRunOnceReady } from "@/hooks/useRunOnceReady"
import { User, getAuth } from "firebase/auth"
import { PropsWithChildren, useState } from "react"
import AuthProvider from "./AuthProvider"

const Auth = (props: PropsWithChildren) => {
  const [user, setUser] = useState<User | null>(null)

  const [loading, setLoading] = useState(true)
  const { mutateAsync: registerWithBE } = useRegisterPlayer()

  useRunOnceReady(true, () => {
    const unsubscribe = getAuth().onAuthStateChanged(async function (user) {
      if (!user) {
        setUser(null)
        setLoading(true)
        await loginAnonymously()
        await registerWithBE({})
        return
      }

      setLoading(false)
      setUser(user)
    })
    return () => {
      unsubscribe()
    }
  })

  if (loading || !user) {
    return <AppLoader className="w-full max-w-none bg-primary-700" />
  }

  return (
    <AuthProvider setUser={setUser} user={user}>
      {props.children}
    </AuthProvider>
  )
}

export default Auth
