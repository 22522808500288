/* tslint:disable */
/* eslint-disable */
/**
 * Poznejme Cesko API
 * Poznejme Cesko API Documentation
 *
 * OpenAPI spec version: v1.0.0
 * Contact: jan.fara@cleevio.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
export * from './apis/admin-banner-api';
export * from './apis/admin-comic-api';
export * from './apis/admin-file-api';
export * from './apis/admin-partner-product-api';
export * from './apis/analytics-api';
export * from './apis/banner-api';
export * from './apis/education-api';
export * from './apis/help-form-api';
export * from './apis/marketplace-api';
export * from './apis/player-api';
export * from './apis/player-bonus-question-api';
export * from './apis/player-comic-api';
export * from './apis/player-game-api';
export * from './apis/player-location-api';
export * from './apis/player-notification-api';
export * from './apis/player-partner-product-api';
export * from './apis/player-room-api';
export * from './apis/player-tournaments-api';
export * from './apis/tournament-open-questions-api';
export * from './apis/user-api';

