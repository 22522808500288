import { register, updatePlayerEmail } from "@/api/auth/api"
import { useMutation, useQueryClient } from "@tanstack/react-query"

export const useRegisterPlayer = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ["registerPlayer"],
    mutationFn: register,
    onSuccess: () => {
      queryClient.invalidateQueries(["playerData"])
    },
  })
}

export const useUpdatePlayerEmail = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ["updatePlayerEmail"],
    mutationFn: updatePlayerEmail,
    onSuccess: () => {
      queryClient.invalidateQueries(["playerData"])
    },
  })
}
