import Container from "@/components/shared/Container"
import { LoadingSpinner } from "rtu-components"

interface Props {
  className?: string
}
const AppLoader = ({ className }: Props) => {
  return (
    <Container className={`flex h-screen w-screen items-center justify-center   ${className}`}>
      <LoadingSpinner color="black" className="h-10 w-10" />
    </Container>
  )
}

export default AppLoader
