import { useCallback, useEffect, useRef } from "react"

export function useRunOnceReady(
  ready: boolean | undefined | null,
  callback: () => void | (() => void) | Promise<void>,
) {
  const stateRef = useRef("none" as "none" | "first" | "done")
  const latestCallback = useLatestCallback(callback)

  ready = stateRef.current !== "none" || ready

  useEffect(() => {
    if (ready && stateRef.current !== "done") {
      stateRef.current = stateRef.current === "none" && isStrictModeDevelopment() ? "first" : "done"
      const destructorOrPromise = latestCallback?.()
      const destructor = destructorOrPromise && "then" in destructorOrPromise ? undefined : destructorOrPromise
      return () => {
        destructor?.()
      }
    }
    return () => undefined
  }, [ready, latestCallback])
}

/**
 * Combines the useLatestValueRef and useCallback to provide a callback that doesn't change reference, yet uses the latest closure.
 */
export const useLatestCallback = <TCallback extends (...args: any[]) => Promise<unknown> | unknown>(
  callback: TCallback,
): TCallback => {
  const latest = useLatestValueRef(callback)
  const latestCallback = useCallback((...args: Parameters<TCallback>) => latest.current(...args), [latest])
  return latestCallback as TCallback
}

export function isStrictModeDevelopment() {
  return process.env.NODE_ENV === "development"
}

export function useLatestValueRef<T>(value: T) {
  const ref = useRef<T>(value)

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref
}
