/* tslint:disable */
/* eslint-disable */
/**
 * Poznejme Cesko API
 * Poznejme Cesko API Documentation
 *
 * OpenAPI spec version: v1.0.0
 * Contact: jan.fara@cleevio.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { AnswerTournamentGameQuestionRequest } from '../models';
import { AnswerTournamentOpenQuestionRequest } from '../models';
import { CreateTournamentRequest } from '../models';
import { CreateTournamentResult } from '../models';
import { GetPlayerTournamentOpenQuestionResult } from '../models';
import { GetTournamentDetailResult } from '../models';
import { GetTournamentGameQuestionQueryResult } from '../models';
import { GetTournamentLeaderboardResult } from '../models';
import { GetTournamentResultsResult } from '../models';
import { JoinToTournamentResult } from '../models';
import { SimplePageSearchTournamentsResult } from '../models';
/**
 * PlayerTournamentsApi - axios parameter creator
 * @export
 */
export const PlayerTournamentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Answer tournament game question
         * @param {AnswerTournamentGameQuestionRequest} body 
         * @param {string} tournamentId 
         * @param {string} questionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        answerTournamentGameQuestion: async (body: AnswerTournamentGameQuestionRequest, tournamentId: string, questionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling answerTournamentGameQuestion.');
            }
            // verify required parameter 'tournamentId' is not null or undefined
            if (tournamentId === null || tournamentId === undefined) {
                throw new RequiredError('tournamentId','Required parameter tournamentId was null or undefined when calling answerTournamentGameQuestion.');
            }
            // verify required parameter 'questionId' is not null or undefined
            if (questionId === null || questionId === undefined) {
                throw new RequiredError('questionId','Required parameter questionId was null or undefined when calling answerTournamentGameQuestion.');
            }
            const localVarPath = `/players/me/tournaments/{tournamentId}/game-questions/{questionId}`
                .replace(`{${"tournamentId"}}`, encodeURIComponent(String(tournamentId)))
                .replace(`{${"questionId"}}`, encodeURIComponent(String(questionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            // authentication x-platform required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Platform")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Platform"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Answer tournament open question
         * @param {AnswerTournamentOpenQuestionRequest} body 
         * @param {string} tournamentId 
         * @param {string} questionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        answerTournamentOpenQuestion: async (body: AnswerTournamentOpenQuestionRequest, tournamentId: string, questionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling answerTournamentOpenQuestion.');
            }
            // verify required parameter 'tournamentId' is not null or undefined
            if (tournamentId === null || tournamentId === undefined) {
                throw new RequiredError('tournamentId','Required parameter tournamentId was null or undefined when calling answerTournamentOpenQuestion.');
            }
            // verify required parameter 'questionId' is not null or undefined
            if (questionId === null || questionId === undefined) {
                throw new RequiredError('questionId','Required parameter questionId was null or undefined when calling answerTournamentOpenQuestion.');
            }
            const localVarPath = `/players/me/tournaments/{tournamentId}/open-questions/{questionId}`
                .replace(`{${"tournamentId"}}`, encodeURIComponent(String(tournamentId)))
                .replace(`{${"questionId"}}`, encodeURIComponent(String(questionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            // authentication x-platform required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Platform")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Platform"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Record when user ends playing comics
         * @param {string} tournamentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        comicsTimeEnd: async (tournamentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tournamentId' is not null or undefined
            if (tournamentId === null || tournamentId === undefined) {
                throw new RequiredError('tournamentId','Required parameter tournamentId was null or undefined when calling comicsTimeEnd.');
            }
            const localVarPath = `/players/me/tournaments/{tournamentId}/time/end`
                .replace(`{${"tournamentId"}}`, encodeURIComponent(String(tournamentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            // authentication x-platform required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Platform")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Platform"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Record when user starts playing comics
         * @param {string} tournamentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        comicsTimeStart: async (tournamentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tournamentId' is not null or undefined
            if (tournamentId === null || tournamentId === undefined) {
                throw new RequiredError('tournamentId','Required parameter tournamentId was null or undefined when calling comicsTimeStart.');
            }
            const localVarPath = `/players/me/tournaments/{tournamentId}/time/start`
                .replace(`{${"tournamentId"}}`, encodeURIComponent(String(tournamentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            // authentication x-platform required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Platform")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Platform"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create tournament
         * @param {CreateTournamentRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTournament: async (body: CreateTournamentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createTournament.');
            }
            const localVarPath = `/players/me/tournaments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            // authentication x-platform required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Platform")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Platform"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary End tournament
         * @param {string} tournamentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        endTournament: async (tournamentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tournamentId' is not null or undefined
            if (tournamentId === null || tournamentId === undefined) {
                throw new RequiredError('tournamentId','Required parameter tournamentId was null or undefined when calling endTournament.');
            }
            const localVarPath = `/players/me/tournaments/{tournamentId}/end`
                .replace(`{${"tournamentId"}}`, encodeURIComponent(String(tournamentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            // authentication x-platform required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Platform")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Platform"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get player's answered tournament open questions
         * @param {string} tournamentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlayerTournamentOpenQuestions: async (tournamentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tournamentId' is not null or undefined
            if (tournamentId === null || tournamentId === undefined) {
                throw new RequiredError('tournamentId','Required parameter tournamentId was null or undefined when calling getPlayerTournamentOpenQuestions.');
            }
            const localVarPath = `/players/me/tournaments/{tournamentId}/open-questions`
                .replace(`{${"tournamentId"}}`, encodeURIComponent(String(tournamentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            // authentication x-platform required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Platform")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Platform"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get tournament detail for creator
         * @param {string} tournamentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTournamentDetail: async (tournamentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tournamentId' is not null or undefined
            if (tournamentId === null || tournamentId === undefined) {
                throw new RequiredError('tournamentId','Required parameter tournamentId was null or undefined when calling getTournamentDetail.');
            }
            const localVarPath = `/players/me/tournaments/{tournamentId}`
                .replace(`{${"tournamentId"}}`, encodeURIComponent(String(tournamentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            // authentication x-platform required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Platform")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Platform"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get results for tournament game question for particular answer position
         * @param {string} tournamentId 
         * @param {string} questionId 
         * @param {number} answerPosition 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTournamentGameQuestionResults: async (tournamentId: string, questionId: string, answerPosition: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tournamentId' is not null or undefined
            if (tournamentId === null || tournamentId === undefined) {
                throw new RequiredError('tournamentId','Required parameter tournamentId was null or undefined when calling getTournamentGameQuestionResults.');
            }
            // verify required parameter 'questionId' is not null or undefined
            if (questionId === null || questionId === undefined) {
                throw new RequiredError('questionId','Required parameter questionId was null or undefined when calling getTournamentGameQuestionResults.');
            }
            // verify required parameter 'answerPosition' is not null or undefined
            if (answerPosition === null || answerPosition === undefined) {
                throw new RequiredError('answerPosition','Required parameter answerPosition was null or undefined when calling getTournamentGameQuestionResults.');
            }
            const localVarPath = `/players/me/tournaments/{tournamentId}/results/{questionId}/answers/{answerPosition}`
                .replace(`{${"tournamentId"}}`, encodeURIComponent(String(tournamentId)))
                .replace(`{${"questionId"}}`, encodeURIComponent(String(questionId)))
                .replace(`{${"answerPosition"}}`, encodeURIComponent(String(answerPosition)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            // authentication x-platform required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Platform")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Platform"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get tournament leaderboard
         * @param {string} tournamentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTournamentLeaderboard: async (tournamentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tournamentId' is not null or undefined
            if (tournamentId === null || tournamentId === undefined) {
                throw new RequiredError('tournamentId','Required parameter tournamentId was null or undefined when calling getTournamentLeaderboard.');
            }
            const localVarPath = `/players/me/tournaments/{tournamentId}/leaderboard`
                .replace(`{${"tournamentId"}}`, encodeURIComponent(String(tournamentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            // authentication x-platform required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Platform")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Platform"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get tournament results for creator
         * @param {string} tournamentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTournamentResults: async (tournamentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tournamentId' is not null or undefined
            if (tournamentId === null || tournamentId === undefined) {
                throw new RequiredError('tournamentId','Required parameter tournamentId was null or undefined when calling getTournamentResults.');
            }
            const localVarPath = `/players/me/tournaments/{tournamentId}/results`
                .replace(`{${"tournamentId"}}`, encodeURIComponent(String(tournamentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            // authentication x-platform required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Platform")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Platform"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Join to tournament
         * @param {string} tournamentCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        joinToTournament: async (tournamentCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tournamentCode' is not null or undefined
            if (tournamentCode === null || tournamentCode === undefined) {
                throw new RequiredError('tournamentCode','Required parameter tournamentCode was null or undefined when calling joinToTournament.');
            }
            const localVarPath = `/players/me/tournaments/{tournamentCode}/join`
                .replace(`{${"tournamentCode"}}`, encodeURIComponent(String(tournamentCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            // authentication x-platform required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Platform")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Platform"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search player's tournaments
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTournaments: async (page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/players/me/tournaments/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            // authentication x-platform required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Platform")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Platform"] = localVarApiKeyValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlayerTournamentsApi - functional programming interface
 * @export
 */
export const PlayerTournamentsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Answer tournament game question
         * @param {AnswerTournamentGameQuestionRequest} body 
         * @param {string} tournamentId 
         * @param {string} questionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async answerTournamentGameQuestion(body: AnswerTournamentGameQuestionRequest, tournamentId: string, questionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await PlayerTournamentsApiAxiosParamCreator(configuration).answerTournamentGameQuestion(body, tournamentId, questionId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Answer tournament open question
         * @param {AnswerTournamentOpenQuestionRequest} body 
         * @param {string} tournamentId 
         * @param {string} questionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async answerTournamentOpenQuestion(body: AnswerTournamentOpenQuestionRequest, tournamentId: string, questionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await PlayerTournamentsApiAxiosParamCreator(configuration).answerTournamentOpenQuestion(body, tournamentId, questionId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Record when user ends playing comics
         * @param {string} tournamentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async comicsTimeEnd(tournamentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await PlayerTournamentsApiAxiosParamCreator(configuration).comicsTimeEnd(tournamentId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Record when user starts playing comics
         * @param {string} tournamentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async comicsTimeStart(tournamentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await PlayerTournamentsApiAxiosParamCreator(configuration).comicsTimeStart(tournamentId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create tournament
         * @param {CreateTournamentRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTournament(body: CreateTournamentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<CreateTournamentResult>>> {
            const localVarAxiosArgs = await PlayerTournamentsApiAxiosParamCreator(configuration).createTournament(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary End tournament
         * @param {string} tournamentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async endTournament(tournamentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await PlayerTournamentsApiAxiosParamCreator(configuration).endTournament(tournamentId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get player's answered tournament open questions
         * @param {string} tournamentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlayerTournamentOpenQuestions(tournamentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<GetPlayerTournamentOpenQuestionResult>>>> {
            const localVarAxiosArgs = await PlayerTournamentsApiAxiosParamCreator(configuration).getPlayerTournamentOpenQuestions(tournamentId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get tournament detail for creator
         * @param {string} tournamentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTournamentDetail(tournamentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<GetTournamentDetailResult>>> {
            const localVarAxiosArgs = await PlayerTournamentsApiAxiosParamCreator(configuration).getTournamentDetail(tournamentId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get results for tournament game question for particular answer position
         * @param {string} tournamentId 
         * @param {string} questionId 
         * @param {number} answerPosition 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTournamentGameQuestionResults(tournamentId: string, questionId: string, answerPosition: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<GetTournamentGameQuestionQueryResult>>> {
            const localVarAxiosArgs = await PlayerTournamentsApiAxiosParamCreator(configuration).getTournamentGameQuestionResults(tournamentId, questionId, answerPosition, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get tournament leaderboard
         * @param {string} tournamentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTournamentLeaderboard(tournamentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<GetTournamentLeaderboardResult>>> {
            const localVarAxiosArgs = await PlayerTournamentsApiAxiosParamCreator(configuration).getTournamentLeaderboard(tournamentId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get tournament results for creator
         * @param {string} tournamentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTournamentResults(tournamentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<GetTournamentResultsResult>>> {
            const localVarAxiosArgs = await PlayerTournamentsApiAxiosParamCreator(configuration).getTournamentResults(tournamentId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Join to tournament
         * @param {string} tournamentCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async joinToTournament(tournamentCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<JoinToTournamentResult>>> {
            const localVarAxiosArgs = await PlayerTournamentsApiAxiosParamCreator(configuration).joinToTournament(tournamentCode, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Search player's tournaments
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchTournaments(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<SimplePageSearchTournamentsResult>>> {
            const localVarAxiosArgs = await PlayerTournamentsApiAxiosParamCreator(configuration).searchTournaments(page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PlayerTournamentsApi - factory interface
 * @export
 */
export const PlayerTournamentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Answer tournament game question
         * @param {AnswerTournamentGameQuestionRequest} body 
         * @param {string} tournamentId 
         * @param {string} questionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async answerTournamentGameQuestion(body: AnswerTournamentGameQuestionRequest, tournamentId: string, questionId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return PlayerTournamentsApiFp(configuration).answerTournamentGameQuestion(body, tournamentId, questionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Answer tournament open question
         * @param {AnswerTournamentOpenQuestionRequest} body 
         * @param {string} tournamentId 
         * @param {string} questionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async answerTournamentOpenQuestion(body: AnswerTournamentOpenQuestionRequest, tournamentId: string, questionId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return PlayerTournamentsApiFp(configuration).answerTournamentOpenQuestion(body, tournamentId, questionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Record when user ends playing comics
         * @param {string} tournamentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async comicsTimeEnd(tournamentId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return PlayerTournamentsApiFp(configuration).comicsTimeEnd(tournamentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Record when user starts playing comics
         * @param {string} tournamentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async comicsTimeStart(tournamentId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return PlayerTournamentsApiFp(configuration).comicsTimeStart(tournamentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create tournament
         * @param {CreateTournamentRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTournament(body: CreateTournamentRequest, options?: AxiosRequestConfig): Promise<AxiosResponse<CreateTournamentResult>> {
            return PlayerTournamentsApiFp(configuration).createTournament(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary End tournament
         * @param {string} tournamentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async endTournament(tournamentId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return PlayerTournamentsApiFp(configuration).endTournament(tournamentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get player's answered tournament open questions
         * @param {string} tournamentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlayerTournamentOpenQuestions(tournamentId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<GetPlayerTournamentOpenQuestionResult>>> {
            return PlayerTournamentsApiFp(configuration).getPlayerTournamentOpenQuestions(tournamentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get tournament detail for creator
         * @param {string} tournamentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTournamentDetail(tournamentId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<GetTournamentDetailResult>> {
            return PlayerTournamentsApiFp(configuration).getTournamentDetail(tournamentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get results for tournament game question for particular answer position
         * @param {string} tournamentId 
         * @param {string} questionId 
         * @param {number} answerPosition 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTournamentGameQuestionResults(tournamentId: string, questionId: string, answerPosition: number, options?: AxiosRequestConfig): Promise<AxiosResponse<GetTournamentGameQuestionQueryResult>> {
            return PlayerTournamentsApiFp(configuration).getTournamentGameQuestionResults(tournamentId, questionId, answerPosition, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get tournament leaderboard
         * @param {string} tournamentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTournamentLeaderboard(tournamentId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<GetTournamentLeaderboardResult>> {
            return PlayerTournamentsApiFp(configuration).getTournamentLeaderboard(tournamentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get tournament results for creator
         * @param {string} tournamentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTournamentResults(tournamentId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<GetTournamentResultsResult>> {
            return PlayerTournamentsApiFp(configuration).getTournamentResults(tournamentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Join to tournament
         * @param {string} tournamentCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async joinToTournament(tournamentCode: string, options?: AxiosRequestConfig): Promise<AxiosResponse<JoinToTournamentResult>> {
            return PlayerTournamentsApiFp(configuration).joinToTournament(tournamentCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search player's tournaments
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchTournaments(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<AxiosResponse<SimplePageSearchTournamentsResult>> {
            return PlayerTournamentsApiFp(configuration).searchTournaments(page, size, sort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PlayerTournamentsApi - object-oriented interface
 * @export
 * @class PlayerTournamentsApi
 * @extends {BaseAPI}
 */
export class PlayerTournamentsApi extends BaseAPI {
    /**
     * 
     * @summary Answer tournament game question
     * @param {AnswerTournamentGameQuestionRequest} body 
     * @param {string} tournamentId 
     * @param {string} questionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerTournamentsApi
     */
    public async answerTournamentGameQuestion(body: AnswerTournamentGameQuestionRequest, tournamentId: string, questionId: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return PlayerTournamentsApiFp(this.configuration).answerTournamentGameQuestion(body, tournamentId, questionId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Answer tournament open question
     * @param {AnswerTournamentOpenQuestionRequest} body 
     * @param {string} tournamentId 
     * @param {string} questionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerTournamentsApi
     */
    public async answerTournamentOpenQuestion(body: AnswerTournamentOpenQuestionRequest, tournamentId: string, questionId: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return PlayerTournamentsApiFp(this.configuration).answerTournamentOpenQuestion(body, tournamentId, questionId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Record when user ends playing comics
     * @param {string} tournamentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerTournamentsApi
     */
    public async comicsTimeEnd(tournamentId: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return PlayerTournamentsApiFp(this.configuration).comicsTimeEnd(tournamentId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Record when user starts playing comics
     * @param {string} tournamentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerTournamentsApi
     */
    public async comicsTimeStart(tournamentId: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return PlayerTournamentsApiFp(this.configuration).comicsTimeStart(tournamentId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Create tournament
     * @param {CreateTournamentRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerTournamentsApi
     */
    public async createTournament(body: CreateTournamentRequest, options?: AxiosRequestConfig) : Promise<AxiosResponse<CreateTournamentResult>> {
        return PlayerTournamentsApiFp(this.configuration).createTournament(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary End tournament
     * @param {string} tournamentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerTournamentsApi
     */
    public async endTournament(tournamentId: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return PlayerTournamentsApiFp(this.configuration).endTournament(tournamentId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get player's answered tournament open questions
     * @param {string} tournamentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerTournamentsApi
     */
    public async getPlayerTournamentOpenQuestions(tournamentId: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<GetPlayerTournamentOpenQuestionResult>>> {
        return PlayerTournamentsApiFp(this.configuration).getPlayerTournamentOpenQuestions(tournamentId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get tournament detail for creator
     * @param {string} tournamentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerTournamentsApi
     */
    public async getTournamentDetail(tournamentId: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<GetTournamentDetailResult>> {
        return PlayerTournamentsApiFp(this.configuration).getTournamentDetail(tournamentId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get results for tournament game question for particular answer position
     * @param {string} tournamentId 
     * @param {string} questionId 
     * @param {number} answerPosition 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerTournamentsApi
     */
    public async getTournamentGameQuestionResults(tournamentId: string, questionId: string, answerPosition: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<GetTournamentGameQuestionQueryResult>> {
        return PlayerTournamentsApiFp(this.configuration).getTournamentGameQuestionResults(tournamentId, questionId, answerPosition, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get tournament leaderboard
     * @param {string} tournamentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerTournamentsApi
     */
    public async getTournamentLeaderboard(tournamentId: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<GetTournamentLeaderboardResult>> {
        return PlayerTournamentsApiFp(this.configuration).getTournamentLeaderboard(tournamentId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get tournament results for creator
     * @param {string} tournamentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerTournamentsApi
     */
    public async getTournamentResults(tournamentId: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<GetTournamentResultsResult>> {
        return PlayerTournamentsApiFp(this.configuration).getTournamentResults(tournamentId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Join to tournament
     * @param {string} tournamentCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerTournamentsApi
     */
    public async joinToTournament(tournamentCode: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<JoinToTournamentResult>> {
        return PlayerTournamentsApiFp(this.configuration).joinToTournament(tournamentCode, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Search player's tournaments
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerTournamentsApi
     */
    public async searchTournaments(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) : Promise<AxiosResponse<SimplePageSearchTournamentsResult>> {
        return PlayerTournamentsApiFp(this.configuration).searchTournaments(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
}
