import { User } from "firebase/auth"
import { Dispatch, SetStateAction, createContext, useContext } from "react"

interface AuthProviderProps extends AuthContextProps {
  children: React.ReactNode
}

interface AuthContextProps {
  user: User | null
  setUser: Dispatch<SetStateAction<User | null>>
}

const AuthContext = createContext<AuthContextProps>({ user: null, setUser: () => {} })

export const useAuthContext = () => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error("useAuthContext must be used within a AuthProvider")
  }
  return context
}

const AuthProvider = ({ children, setUser, user }: AuthProviderProps) => {
  return <AuthContext.Provider value={{ user, setUser }}>{children}</AuthContext.Provider>
}

export default AuthProvider
