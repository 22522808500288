import Head from "next/head"
import { useRouter } from "next/router"

const isProduction = process.env.APP_ENV === "production"

type Props = { title?: string; description?: string; noIndex?: boolean }

const SEO = (props: Props) => {
  const router = useRouter()

  const title = ["Poznejme Česko", props.title].filter(Boolean).join(" - ")
  const description =
    props.description ||
    "Prozkoumejte českou historii skrze interaktivní komiksy, získejte grošáky za odpovědi a vyměňte je za herní doplňky!"
  const noIndex = props.noIndex ?? !isProduction
  const url = `${process.env.NEXT_PUBLIC_BASE_URL}/${router.asPath}`
  const imgUrl = `${process.env.NEXT_PUBLIC_BASE_URL}/logo.png`

  return (
    <Head>
      <title key="title">{title}</title>
      <meta name="description" content={description} key="description" />
      <meta property="og:title" content={title} key="og:title" />
      <meta property="og:description" content={description} key="og:description" />
      <meta property="og:url" content={url} key="og:url" />
      <meta property="og:image" content={imgUrl} key="og:image" />
      <meta property="og:type" content="website" key="og:type" />
      {noIndex && <meta name="robots" content="noindex,nofollow" key="robots" />}
    </Head>
  )
}

export default SEO
