/* tslint:disable */
/* eslint-disable */
/**
 * Poznejme Cesko API
 * Poznejme Cesko API Documentation
 *
 * OpenAPI spec version: v1.0.0
 * Contact: jan.fara@cleevio.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { CreateComicRequest } from '../models';
import { CreateComicResult } from '../models';
import { GetAdminComicResult } from '../models';
import { SimplePageSearchAdminComicResult } from '../models';
import { UpdateAdminComicRequest } from '../models';
/**
 * AdminComicApi - axios parameter creator
 * @export
 */
export const AdminComicApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create comic
         * @param {CreateComicRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createComic: async (body: CreateComicRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createComic.');
            }
            const localVarPath = `/admin/comics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            // authentication x-platform required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Platform")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Platform"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete comic
         * @param {string} comicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteComic: async (comicId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'comicId' is not null or undefined
            if (comicId === null || comicId === undefined) {
                throw new RequiredError('comicId','Required parameter comicId was null or undefined when calling deleteComic.');
            }
            const localVarPath = `/admin/comics/{comicId}`
                .replace(`{${"comicId"}}`, encodeURIComponent(String(comicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            // authentication x-platform required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Platform")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Platform"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get comic detail
         * @param {string} comicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminComic: async (comicId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'comicId' is not null or undefined
            if (comicId === null || comicId === undefined) {
                throw new RequiredError('comicId','Required parameter comicId was null or undefined when calling getAdminComic.');
            }
            const localVarPath = `/admin/comics/{comicId}`
                .replace(`{${"comicId"}}`, encodeURIComponent(String(comicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            // authentication x-platform required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Platform")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Platform"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of comics
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAdminComic: async (page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/comics/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            // authentication x-platform required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Platform")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Platform"] = localVarApiKeyValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update comic
         * @param {UpdateAdminComicRequest} body 
         * @param {string} comicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminComic: async (body: UpdateAdminComicRequest, comicId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateAdminComic.');
            }
            // verify required parameter 'comicId' is not null or undefined
            if (comicId === null || comicId === undefined) {
                throw new RequiredError('comicId','Required parameter comicId was null or undefined when calling updateAdminComic.');
            }
            const localVarPath = `/admin/comics/{comicId}`
                .replace(`{${"comicId"}}`, encodeURIComponent(String(comicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            // authentication x-platform required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Platform")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Platform"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload new file for existing comic
         * @param {string} comicId 
         * @param {Blob} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadNewFileForComicForm: async (comicId: string, file?: Blob, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'comicId' is not null or undefined
            if (comicId === null || comicId === undefined) {
                throw new RequiredError('comicId','Required parameter comicId was null or undefined when calling uploadNewFileForComicForm.');
            }
            const localVarPath = `/admin/comics/{comicId}/file`
                .replace(`{${"comicId"}}`, encodeURIComponent(String(comicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            // authentication x-platform required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Platform")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Platform"] = localVarApiKeyValue;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminComicApi - functional programming interface
 * @export
 */
export const AdminComicApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create comic
         * @param {CreateComicRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createComic(body: CreateComicRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<CreateComicResult>>> {
            const localVarAxiosArgs = await AdminComicApiAxiosParamCreator(configuration).createComic(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete comic
         * @param {string} comicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteComic(comicId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await AdminComicApiAxiosParamCreator(configuration).deleteComic(comicId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get comic detail
         * @param {string} comicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminComic(comicId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<GetAdminComicResult>>> {
            const localVarAxiosArgs = await AdminComicApiAxiosParamCreator(configuration).getAdminComic(comicId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary List of comics
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchAdminComic(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<SimplePageSearchAdminComicResult>>> {
            const localVarAxiosArgs = await AdminComicApiAxiosParamCreator(configuration).searchAdminComic(page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update comic
         * @param {UpdateAdminComicRequest} body 
         * @param {string} comicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAdminComic(body: UpdateAdminComicRequest, comicId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await AdminComicApiAxiosParamCreator(configuration).updateAdminComic(body, comicId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Upload new file for existing comic
         * @param {string} comicId 
         * @param {Blob} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadNewFileForComicForm(comicId: string, file?: Blob, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await AdminComicApiAxiosParamCreator(configuration).uploadNewFileForComicForm(comicId, file, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AdminComicApi - factory interface
 * @export
 */
export const AdminComicApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Create comic
         * @param {CreateComicRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createComic(body: CreateComicRequest, options?: AxiosRequestConfig): Promise<AxiosResponse<CreateComicResult>> {
            return AdminComicApiFp(configuration).createComic(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete comic
         * @param {string} comicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteComic(comicId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return AdminComicApiFp(configuration).deleteComic(comicId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get comic detail
         * @param {string} comicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminComic(comicId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<GetAdminComicResult>> {
            return AdminComicApiFp(configuration).getAdminComic(comicId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of comics
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchAdminComic(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<AxiosResponse<SimplePageSearchAdminComicResult>> {
            return AdminComicApiFp(configuration).searchAdminComic(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update comic
         * @param {UpdateAdminComicRequest} body 
         * @param {string} comicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAdminComic(body: UpdateAdminComicRequest, comicId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return AdminComicApiFp(configuration).updateAdminComic(body, comicId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload new file for existing comic
         * @param {string} comicId 
         * @param {Blob} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadNewFileForComicForm(comicId: string, file?: Blob, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return AdminComicApiFp(configuration).uploadNewFileForComicForm(comicId, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminComicApi - object-oriented interface
 * @export
 * @class AdminComicApi
 * @extends {BaseAPI}
 */
export class AdminComicApi extends BaseAPI {
    /**
     * 
     * @summary Create comic
     * @param {CreateComicRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminComicApi
     */
    public async createComic(body: CreateComicRequest, options?: AxiosRequestConfig) : Promise<AxiosResponse<CreateComicResult>> {
        return AdminComicApiFp(this.configuration).createComic(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Delete comic
     * @param {string} comicId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminComicApi
     */
    public async deleteComic(comicId: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return AdminComicApiFp(this.configuration).deleteComic(comicId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get comic detail
     * @param {string} comicId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminComicApi
     */
    public async getAdminComic(comicId: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<GetAdminComicResult>> {
        return AdminComicApiFp(this.configuration).getAdminComic(comicId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary List of comics
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminComicApi
     */
    public async searchAdminComic(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) : Promise<AxiosResponse<SimplePageSearchAdminComicResult>> {
        return AdminComicApiFp(this.configuration).searchAdminComic(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Update comic
     * @param {UpdateAdminComicRequest} body 
     * @param {string} comicId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminComicApi
     */
    public async updateAdminComic(body: UpdateAdminComicRequest, comicId: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return AdminComicApiFp(this.configuration).updateAdminComic(body, comicId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Upload new file for existing comic
     * @param {string} comicId 
     * @param {Blob} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminComicApi
     */
    public async uploadNewFileForComicForm(comicId: string, file?: Blob, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return AdminComicApiFp(this.configuration).uploadNewFileForComicForm(comicId, file, options).then((request) => request(this.axios, this.basePath));
    }
}
