/* tslint:disable */
/* eslint-disable */
/**
 * Poznejme Cesko API
 * Poznejme Cesko API Documentation
 *
 * OpenAPI spec version: v1.0.0
 * Contact: jan.fara@cleevio.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { GetEducationResult } from '../models';
import { SearchEducationRequest } from '../models';
import { SimplePageSearchEducationResult } from '../models';
/**
 * EducationApi - axios parameter creator
 * @export
 */
export const EducationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get education detail
         * @param {string} educationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEducation: async (educationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'educationId' is not null or undefined
            if (educationId === null || educationId === undefined) {
                throw new RequiredError('educationId','Required parameter educationId was null or undefined when calling getEducation.');
            }
            const localVarPath = `/educations/{educationId}`
                .replace(`{${"educationId"}}`, encodeURIComponent(String(educationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            // authentication x-platform required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Platform")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Platform"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get education detail by slug
         * @param {string} educationSlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEducationBySlug: async (educationSlug: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'educationSlug' is not null or undefined
            if (educationSlug === null || educationSlug === undefined) {
                throw new RequiredError('educationSlug','Required parameter educationSlug was null or undefined when calling getEducationBySlug.');
            }
            const localVarPath = `/educations/slug/{educationSlug}`
                .replace(`{${"educationSlug"}}`, encodeURIComponent(String(educationSlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            // authentication x-platform required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Platform")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Platform"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search educations
         * @param {SearchEducationRequest} body 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchEducation: async (body: SearchEducationRequest, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling searchEducation.');
            }
            const localVarPath = `/educations/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            // authentication x-platform required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Platform")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Platform"] = localVarApiKeyValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EducationApi - functional programming interface
 * @export
 */
export const EducationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get education detail
         * @param {string} educationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEducation(educationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<GetEducationResult>>> {
            const localVarAxiosArgs = await EducationApiAxiosParamCreator(configuration).getEducation(educationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get education detail by slug
         * @param {string} educationSlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEducationBySlug(educationSlug: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<GetEducationResult>>> {
            const localVarAxiosArgs = await EducationApiAxiosParamCreator(configuration).getEducationBySlug(educationSlug, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Search educations
         * @param {SearchEducationRequest} body 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchEducation(body: SearchEducationRequest, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<SimplePageSearchEducationResult>>> {
            const localVarAxiosArgs = await EducationApiAxiosParamCreator(configuration).searchEducation(body, page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * EducationApi - factory interface
 * @export
 */
export const EducationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get education detail
         * @param {string} educationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEducation(educationId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<GetEducationResult>> {
            return EducationApiFp(configuration).getEducation(educationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get education detail by slug
         * @param {string} educationSlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEducationBySlug(educationSlug: string, options?: AxiosRequestConfig): Promise<AxiosResponse<GetEducationResult>> {
            return EducationApiFp(configuration).getEducationBySlug(educationSlug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search educations
         * @param {SearchEducationRequest} body 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchEducation(body: SearchEducationRequest, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<AxiosResponse<SimplePageSearchEducationResult>> {
            return EducationApiFp(configuration).searchEducation(body, page, size, sort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EducationApi - object-oriented interface
 * @export
 * @class EducationApi
 * @extends {BaseAPI}
 */
export class EducationApi extends BaseAPI {
    /**
     * 
     * @summary Get education detail
     * @param {string} educationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducationApi
     */
    public async getEducation(educationId: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<GetEducationResult>> {
        return EducationApiFp(this.configuration).getEducation(educationId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get education detail by slug
     * @param {string} educationSlug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducationApi
     */
    public async getEducationBySlug(educationSlug: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<GetEducationResult>> {
        return EducationApiFp(this.configuration).getEducationBySlug(educationSlug, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Search educations
     * @param {SearchEducationRequest} body 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducationApi
     */
    public async searchEducation(body: SearchEducationRequest, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) : Promise<AxiosResponse<SimplePageSearchEducationResult>> {
        return EducationApiFp(this.configuration).searchEducation(body, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
}
