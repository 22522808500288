/* tslint:disable */
/* eslint-disable */
/**
 * Poznejme Cesko API
 * Poznejme Cesko API Documentation
 *
 * OpenAPI spec version: v1.0.0
 * Contact: jan.fara@cleevio.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { DownloadPlayerComicRequest } from '../models';
import { DownloadPlayerComicResult } from '../models';
import { GetPlayerComicResult } from '../models';
import { SearchPlayerComicRequest } from '../models';
import { SimplePageSearchPlayerComicResult } from '../models';
import { SimplePageSearchRecommendedComicResult } from '../models';
/**
 * PlayerComicApi - axios parameter creator
 * @export
 */
export const PlayerComicApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all comics for offline mode
         * @param {DownloadPlayerComicRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        download: async (body: DownloadPlayerComicRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling download.');
            }
            const localVarPath = `/players/me/comics/download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            // authentication x-platform required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Platform")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Platform"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get comic detail by id
         * @param {string} comicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlayerComic: async (comicId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'comicId' is not null or undefined
            if (comicId === null || comicId === undefined) {
                throw new RequiredError('comicId','Required parameter comicId was null or undefined when calling getPlayerComic.');
            }
            const localVarPath = `/players/me/comics/{comicId}`
                .replace(`{${"comicId"}}`, encodeURIComponent(String(comicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            // authentication x-platform required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Platform")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Platform"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get comic detail by slug
         * @param {string} comicSlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlayerComicBySlug: async (comicSlug: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'comicSlug' is not null or undefined
            if (comicSlug === null || comicSlug === undefined) {
                throw new RequiredError('comicSlug','Required parameter comicSlug was null or undefined when calling getPlayerComicBySlug.');
            }
            const localVarPath = `/players/me/comics/slug/{comicSlug}`
                .replace(`{${"comicSlug"}}`, encodeURIComponent(String(comicSlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            // authentication x-platform required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Platform")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Platform"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search comic for map view
         * @param {SearchPlayerComicRequest} body 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchComic: async (body: SearchPlayerComicRequest, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling searchComic.');
            }
            const localVarPath = `/players/me/comics/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            // authentication x-platform required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Platform")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Platform"] = localVarApiKeyValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get recommended comics
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchRecommendedComic: async (page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/players/me/comics/recommended/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            // authentication x-platform required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Platform")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Platform"] = localVarApiKeyValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlayerComicApi - functional programming interface
 * @export
 */
export const PlayerComicApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all comics for offline mode
         * @param {DownloadPlayerComicRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async download(body: DownloadPlayerComicRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<DownloadPlayerComicResult>>>> {
            const localVarAxiosArgs = await PlayerComicApiAxiosParamCreator(configuration).download(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get comic detail by id
         * @param {string} comicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlayerComic(comicId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<GetPlayerComicResult>>> {
            const localVarAxiosArgs = await PlayerComicApiAxiosParamCreator(configuration).getPlayerComic(comicId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get comic detail by slug
         * @param {string} comicSlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlayerComicBySlug(comicSlug: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<GetPlayerComicResult>>> {
            const localVarAxiosArgs = await PlayerComicApiAxiosParamCreator(configuration).getPlayerComicBySlug(comicSlug, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Search comic for map view
         * @param {SearchPlayerComicRequest} body 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchComic(body: SearchPlayerComicRequest, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<SimplePageSearchPlayerComicResult>>> {
            const localVarAxiosArgs = await PlayerComicApiAxiosParamCreator(configuration).searchComic(body, page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get recommended comics
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchRecommendedComic(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<SimplePageSearchRecommendedComicResult>>> {
            const localVarAxiosArgs = await PlayerComicApiAxiosParamCreator(configuration).searchRecommendedComic(page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PlayerComicApi - factory interface
 * @export
 */
export const PlayerComicApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get all comics for offline mode
         * @param {DownloadPlayerComicRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async download(body: DownloadPlayerComicRequest, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<DownloadPlayerComicResult>>> {
            return PlayerComicApiFp(configuration).download(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get comic detail by id
         * @param {string} comicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlayerComic(comicId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<GetPlayerComicResult>> {
            return PlayerComicApiFp(configuration).getPlayerComic(comicId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get comic detail by slug
         * @param {string} comicSlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlayerComicBySlug(comicSlug: string, options?: AxiosRequestConfig): Promise<AxiosResponse<GetPlayerComicResult>> {
            return PlayerComicApiFp(configuration).getPlayerComicBySlug(comicSlug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search comic for map view
         * @param {SearchPlayerComicRequest} body 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchComic(body: SearchPlayerComicRequest, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<AxiosResponse<SimplePageSearchPlayerComicResult>> {
            return PlayerComicApiFp(configuration).searchComic(body, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get recommended comics
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchRecommendedComic(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<AxiosResponse<SimplePageSearchRecommendedComicResult>> {
            return PlayerComicApiFp(configuration).searchRecommendedComic(page, size, sort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PlayerComicApi - object-oriented interface
 * @export
 * @class PlayerComicApi
 * @extends {BaseAPI}
 */
export class PlayerComicApi extends BaseAPI {
    /**
     * 
     * @summary Get all comics for offline mode
     * @param {DownloadPlayerComicRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerComicApi
     */
    public async download(body: DownloadPlayerComicRequest, options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<DownloadPlayerComicResult>>> {
        return PlayerComicApiFp(this.configuration).download(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get comic detail by id
     * @param {string} comicId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerComicApi
     */
    public async getPlayerComic(comicId: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<GetPlayerComicResult>> {
        return PlayerComicApiFp(this.configuration).getPlayerComic(comicId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get comic detail by slug
     * @param {string} comicSlug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerComicApi
     */
    public async getPlayerComicBySlug(comicSlug: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<GetPlayerComicResult>> {
        return PlayerComicApiFp(this.configuration).getPlayerComicBySlug(comicSlug, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Search comic for map view
     * @param {SearchPlayerComicRequest} body 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerComicApi
     */
    public async searchComic(body: SearchPlayerComicRequest, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) : Promise<AxiosResponse<SimplePageSearchPlayerComicResult>> {
        return PlayerComicApiFp(this.configuration).searchComic(body, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get recommended comics
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerComicApi
     */
    public async searchRecommendedComic(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) : Promise<AxiosResponse<SimplePageSearchRecommendedComicResult>> {
        return PlayerComicApiFp(this.configuration).searchRecommendedComic(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
}
