import axios from "axios"
import { getAuth } from "firebase/auth"

export const axiosInstance = axios.create({
  headers: {
    "X-Platform": "WEB",
    Accept: "application/poznejme-cesko-v1+json",
  },
})
axiosInstance.interceptors.response.use(function (response) {
  if (response.status === 401) {
    const auth = getAuth()
    auth.signOut()
  }
  return response
})
