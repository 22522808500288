import { FirebaseErrorsMessages } from "@/components/core/Auth/messages"
import { logFirebaseError } from "@/utils/messaging"
import {
  EmailAuthProvider,
  FacebookAuthProvider,
  GoogleAuthProvider,
  User,
  applyActionCode,
  checkActionCode,
  confirmPasswordReset,
  getAuth,
  linkWithCredential,
  linkWithPopup,
  reauthenticateWithCredential,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInAnonymously,
  signInWithCredential,
  signInWithEmailAndPassword,
  signInWithPopup,
  updateEmail,
  updatePassword,
  updateProfile,
} from "firebase/auth"

interface FirebaseError extends Error {
  code: string
  customData?: Record<string, string>
}

export const getHeaders = async () => {
  const idToken = await getFirebaseIDToken()

  return {
    Authorization: `Bearer ${idToken}`,
    "X-Platform": "WEB",
    Accept: "application/poznejme-cesko-v1+json",
  }
}

export const updateUserPassword = async (email: string, password: string, newPassword: string) => {
  const auth = getAuth()
  const user = auth.currentUser
  if (!user) {
    return { data: null, error: FirebaseErrorsMessages.USER_NOT_FOUND }
  }

  const signInProvider = await getFirebaseSignInProvider()

  if (typeof signInProvider === "string" && signInProvider !== "password") {
    return { data: null, error: FirebaseErrorsMessages.AUTHENTICATION_WITHOUT_PASSWORD_AND_EMAIL }
  }

  const credential = EmailAuthProvider.credential(email, password)

  try {
    const { user: reauthenticateUser } = await reauthenticateWithCredential(user, credential)
    await updatePassword(reauthenticateUser, newPassword)
  } catch (e) {
    const error = e as FirebaseError
    logFirebaseError("updateUserPassword", error.code)

    return { data: null, error: error.code }
  }
}
export const updateUserEmail = async (oldEmail: string, newEmail: string, password: string) => {
  const auth = getAuth()
  const user = auth.currentUser
  if (!user) {
    return { data: null, error: FirebaseErrorsMessages.USER_NOT_FOUND }
  }

  const signInProvider = await getFirebaseSignInProvider()

  if (typeof signInProvider === "string" && signInProvider !== "password") {
    return { data: null, error: FirebaseErrorsMessages.AUTHENTICATION_WITHOUT_PASSWORD_AND_EMAIL }
  }

  const credential = EmailAuthProvider.credential(oldEmail, password)

  try {
    const { user: reauthenticateUser } = await reauthenticateWithCredential(user, credential)
    await updateEmail(reauthenticateUser, newEmail)
  } catch (e) {
    const error = e as FirebaseError
    logFirebaseError("updateUserEmail", error.code)

    return { data: null, error: error.code }
  }
}

export const loginWithEmail = async (email: string, password: string) => {
  const auth = getAuth()

  try {
    const result = await signInWithEmailAndPassword(auth, email, password)

    return { data: result, error: null }
  } catch (e) {
    const error = e as FirebaseError
    logFirebaseError("signInWithEmailAndPassword", error.code)

    return { data: null, error: error.code }
  }
}

export const registerWithEmail = async (displayName: string, email: string, password: string) => {
  const auth = getAuth()
  const user = auth.currentUser
  const credential = EmailAuthProvider.credential(email, password)

  if (!user) {
    return { data: null, error: FirebaseErrorsMessages.USER_NOT_FOUND }
  }

  try {
    const result = await linkWithCredential(user, credential)

    await signInWithEmailAndPassword(auth, email, password)
    await updateProfile(result.user, { displayName })

    return { data: result, error: null }
  } catch (e) {
    const error = e as FirebaseError
    logFirebaseError("createUserWithEmailAndPassword", error.code)

    return { data: null, error: error.code }
  }
}

export const loginAnonymously = async () => {
  const auth = getAuth()

  try {
    const result = await signInAnonymously(auth)

    return { data: result, error: null }
  } catch (e) {
    const error = e as FirebaseError
    logFirebaseError("loginAnonymously", error.code)

    return { data: null, error: error.code }
  }
}

export const loginWithFacebook = async () => {
  const auth = getAuth()
  const provider = new FacebookAuthProvider()

  const currentUser = auth.currentUser

  if (currentUser) {
    try {
      await linkWithPopup(currentUser, provider)
    } catch (linkError) {
      if (linkError.code === "auth/credential-already-in-use") {
        const credential = FacebookAuthProvider.credentialFromError(linkError)
        const signInResult = await signInWithCredential(auth, credential!)

        return signInResult.user
      } else {
        throw linkError
      }
    }
  }
}

export const loginWithGoogle = async () => {
  const auth = getAuth()
  const provider = new GoogleAuthProvider()

  const currentUser = auth.currentUser

  if (currentUser) {
    try {
      await linkWithPopup(currentUser, provider)
    } catch (linkError) {
      if (linkError.code === "auth/credential-already-in-use") {
        const credential = GoogleAuthProvider.credentialFromError(linkError)
        const signInResult = await signInWithCredential(auth, credential!)

        return signInResult.user
      } else {
        throw linkError
      }
    }
  }
}

export const logout = async () => {
  const auth = getAuth()

  try {
    await auth.signOut()
  } catch (e) {
    const error = e as FirebaseError
    logFirebaseError("signOut", error.code)
  }
}

export const resetPassword = async (email: string) => {
  const auth = getAuth()

  try {
    await sendPasswordResetEmail(auth, email)

    return { data: true, error: null }
  } catch (e) {
    const error = e as FirebaseError
    logFirebaseError("sendPasswordResetEmail", error.code)

    return { data: null, error: error.code }
  }
}

export const confirmResetPassword = async (code: string, password: string) => {
  const auth = getAuth()

  try {
    await confirmPasswordReset(auth, code, password)

    return { data: true, error: null }
  } catch (e) {
    const error = e as FirebaseError
    logFirebaseError("confirmPasswordReset", error.code)

    return { data: null, error: error.code }
  }
}

export const applyVerificationCode = async (code: string) => {
  const auth = getAuth()

  try {
    await applyActionCode(auth, code)

    return { data: true, error: null }
  } catch (e) {
    const error = e as FirebaseError
    logFirebaseError("applyActionCode", error.code)

    return { data: null, error: error.code }
  }
}

export const handleRecoverEmail = async (code: string) => {
  const auth = getAuth()

  try {
    const { data } = await checkActionCode(auth, code)
    await applyActionCode(auth, code)

    return { data: data.email, error: null }
  } catch (e) {
    const error = e as FirebaseError
    logFirebaseError("applyActionCode", error.code)

    return { data: null, error: error.code }
  }
}

export const sendVerificationEmailToUser = async (firebaseUser?: User) => {
  const auth = getAuth()
  const user = firebaseUser ?? auth.currentUser

  if (!user) {
    return { data: null, error: FirebaseErrorsMessages.USER_NOT_FOUND }
  }

  try {
    await sendEmailVerification(user)

    return { data: true, error: null }
  } catch (e) {
    const error = e as FirebaseError
    logFirebaseError("sendEmailVerification", error.code)

    return { data: null, error: error.code }
  }
}

export async function getFirebaseIDToken() {
  const user = await getFirebaseUser()
  const idTokenResult = await user?.getIdTokenResult()

  return idTokenResult?.token ?? null
}

export async function getFirebaseSignInProvider() {
  const user = await getFirebaseUser()
  const signInProvider = (await user?.getIdTokenResult())?.signInProvider

  return signInProvider ?? null
}

function getFirebaseUser(): Promise<User | null> {
  const auth = getAuth()

  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      unsubscribe()
      resolve(user)
    }, reject)
  })
}
