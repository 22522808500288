/* tslint:disable */
/* eslint-disable */
/**
 * Poznejme Cesko API
 * Poznejme Cesko API Documentation
 *
 * OpenAPI spec version: v1.0.0
 * Contact: jan.fara@cleevio.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { GetAdminFileResult } from '../models';
import { UploadFileResult } from '../models';
import { UploadImageBannerResult } from '../models';
import { UploadZipFileResult } from '../models';
/**
 * AdminFileApi - axios parameter creator
 * @export
 */
export const AdminFileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFile: async (fileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            if (fileId === null || fileId === undefined) {
                throw new RequiredError('fileId','Required parameter fileId was null or undefined when calling deleteFile.');
            }
            const localVarPath = `/admin/files/{fileId}`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            // authentication x-platform required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Platform")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Platform"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteZipFile: async (fileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            if (fileId === null || fileId === undefined) {
                throw new RequiredError('fileId','Required parameter fileId was null or undefined when calling deleteZipFile.');
            }
            const localVarPath = `/admin/files/{fileId}/zip`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            // authentication x-platform required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Platform")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Platform"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFile: async (fileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            if (fileId === null || fileId === undefined) {
                throw new RequiredError('fileId','Required parameter fileId was null or undefined when calling getFile.');
            }
            const localVarPath = `/admin/files/{fileId}`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            // authentication x-platform required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Platform")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Platform"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Supported formats jpg, jpeg, png, svg, webp, mp4, m4a, aac, ogg, wav, mp3.
         * @param {Blob} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFileForm: async (file?: Blob, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            // authentication x-platform required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Platform")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Platform"] = localVarApiKeyValue;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Supported formats jpg, jpeg, png, gif
         * @param {Blob} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImageForm: async (file?: Blob, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/files/image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            // authentication x-platform required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Platform")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Platform"] = localVarApiKeyValue;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Blob} [zipFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadZipFileForm: async (zipFile?: Blob, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/files/zip`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            // authentication x-platform required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Platform")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Platform"] = localVarApiKeyValue;
            }


            if (zipFile !== undefined) { 
                localVarFormParams.append('zipFile', zipFile as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminFileApi - functional programming interface
 * @export
 */
export const AdminFileApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFile(fileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await AdminFileApiAxiosParamCreator(configuration).deleteFile(fileId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteZipFile(fileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await AdminFileApiAxiosParamCreator(configuration).deleteZipFile(fileId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFile(fileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<GetAdminFileResult>>> {
            const localVarAxiosArgs = await AdminFileApiAxiosParamCreator(configuration).getFile(fileId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Supported formats jpg, jpeg, png, svg, webp, mp4, m4a, aac, ogg, wav, mp3.
         * @param {Blob} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadFileForm(file?: Blob, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<UploadFileResult>>> {
            const localVarAxiosArgs = await AdminFileApiAxiosParamCreator(configuration).uploadFileForm(file, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Supported formats jpg, jpeg, png, gif
         * @param {Blob} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadImageForm(file?: Blob, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<UploadImageBannerResult>>> {
            const localVarAxiosArgs = await AdminFileApiAxiosParamCreator(configuration).uploadImageForm(file, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Blob} [zipFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadZipFileForm(zipFile?: Blob, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<UploadZipFileResult>>> {
            const localVarAxiosArgs = await AdminFileApiAxiosParamCreator(configuration).uploadZipFileForm(zipFile, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AdminFileApi - factory interface
 * @export
 */
export const AdminFileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFile(fileId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return AdminFileApiFp(configuration).deleteFile(fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteZipFile(fileId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return AdminFileApiFp(configuration).deleteZipFile(fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFile(fileId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<GetAdminFileResult>> {
            return AdminFileApiFp(configuration).getFile(fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Supported formats jpg, jpeg, png, svg, webp, mp4, m4a, aac, ogg, wav, mp3.
         * @param {Blob} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadFileForm(file?: Blob, options?: AxiosRequestConfig): Promise<AxiosResponse<UploadFileResult>> {
            return AdminFileApiFp(configuration).uploadFileForm(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Supported formats jpg, jpeg, png, gif
         * @param {Blob} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadImageForm(file?: Blob, options?: AxiosRequestConfig): Promise<AxiosResponse<UploadImageBannerResult>> {
            return AdminFileApiFp(configuration).uploadImageForm(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Blob} [zipFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadZipFileForm(zipFile?: Blob, options?: AxiosRequestConfig): Promise<AxiosResponse<UploadZipFileResult>> {
            return AdminFileApiFp(configuration).uploadZipFileForm(zipFile, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminFileApi - object-oriented interface
 * @export
 * @class AdminFileApi
 * @extends {BaseAPI}
 */
export class AdminFileApi extends BaseAPI {
    /**
     * 
     * @param {string} fileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFileApi
     */
    public async deleteFile(fileId: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return AdminFileApiFp(this.configuration).deleteFile(fileId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} fileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFileApi
     */
    public async deleteZipFile(fileId: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return AdminFileApiFp(this.configuration).deleteZipFile(fileId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} fileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFileApi
     */
    public async getFile(fileId: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<GetAdminFileResult>> {
        return AdminFileApiFp(this.configuration).getFile(fileId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Supported formats jpg, jpeg, png, svg, webp, mp4, m4a, aac, ogg, wav, mp3.
     * @param {Blob} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFileApi
     */
    public async uploadFileForm(file?: Blob, options?: AxiosRequestConfig) : Promise<AxiosResponse<UploadFileResult>> {
        return AdminFileApiFp(this.configuration).uploadFileForm(file, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Supported formats jpg, jpeg, png, gif
     * @param {Blob} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFileApi
     */
    public async uploadImageForm(file?: Blob, options?: AxiosRequestConfig) : Promise<AxiosResponse<UploadImageBannerResult>> {
        return AdminFileApiFp(this.configuration).uploadImageForm(file, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {Blob} [zipFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFileApi
     */
    public async uploadZipFileForm(zipFile?: Blob, options?: AxiosRequestConfig) : Promise<AxiosResponse<UploadZipFileResult>> {
        return AdminFileApiFp(this.configuration).uploadZipFileForm(zipFile, options).then((request) => request(this.axios, this.basePath));
    }
}
