export enum FirebaseErrorsMessages {
  WRONG_PASSWORD = "auth/wrong-password",
  USER_NOT_FOUND = "auth/user-not-found",
  TOO_MANY_REQUESTS = "auth/too-many-requests",
  ALREADY_IN_USE = "auth/email-already-in-use",
  AUTHENTICATION_WITHOUT_PASSWORD_AND_EMAIL = "auth/authentication-without-password-and-email",
  INVALID_CREDENTIALS = "auth/invalid-login-credentials",
}

export enum FirebaseSuccessMessages {
  registration = "User successfully registered",
}
