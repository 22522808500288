/* tslint:disable */
/* eslint-disable */
/**
 * Poznejme Cesko API
 * Poznejme Cesko API Documentation
 *
 * OpenAPI spec version: v1.0.0
 * Contact: jan.fara@cleevio.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { GetPlayerBonusQuestionResult } from '../models';
import { PlayBonusQuestionRequest } from '../models';
/**
 * PlayerBonusQuestionApi - axios parameter creator
 * @export
 */
export const PlayerBonusQuestionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get bonus question detail
         * @param {string} bonusQuestionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBonusQuestion: async (bonusQuestionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bonusQuestionId' is not null or undefined
            if (bonusQuestionId === null || bonusQuestionId === undefined) {
                throw new RequiredError('bonusQuestionId','Required parameter bonusQuestionId was null or undefined when calling getBonusQuestion.');
            }
            const localVarPath = `/players/me/bonus-questions/{bonusQuestionId}`
                .replace(`{${"bonusQuestionId"}}`, encodeURIComponent(String(bonusQuestionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            // authentication x-platform required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Platform")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Platform"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Confirm bonus question as played by player
         * @param {PlayBonusQuestionRequest} body 
         * @param {string} bonusQuestionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playBonusQuestion: async (body: PlayBonusQuestionRequest, bonusQuestionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling playBonusQuestion.');
            }
            // verify required parameter 'bonusQuestionId' is not null or undefined
            if (bonusQuestionId === null || bonusQuestionId === undefined) {
                throw new RequiredError('bonusQuestionId','Required parameter bonusQuestionId was null or undefined when calling playBonusQuestion.');
            }
            const localVarPath = `/players/me/bonus-questions/{bonusQuestionId}/play`
                .replace(`{${"bonusQuestionId"}}`, encodeURIComponent(String(bonusQuestionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            // authentication x-platform required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Platform")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Platform"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlayerBonusQuestionApi - functional programming interface
 * @export
 */
export const PlayerBonusQuestionApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get bonus question detail
         * @param {string} bonusQuestionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBonusQuestion(bonusQuestionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<GetPlayerBonusQuestionResult>>> {
            const localVarAxiosArgs = await PlayerBonusQuestionApiAxiosParamCreator(configuration).getBonusQuestion(bonusQuestionId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Confirm bonus question as played by player
         * @param {PlayBonusQuestionRequest} body 
         * @param {string} bonusQuestionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async playBonusQuestion(body: PlayBonusQuestionRequest, bonusQuestionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await PlayerBonusQuestionApiAxiosParamCreator(configuration).playBonusQuestion(body, bonusQuestionId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PlayerBonusQuestionApi - factory interface
 * @export
 */
export const PlayerBonusQuestionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get bonus question detail
         * @param {string} bonusQuestionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBonusQuestion(bonusQuestionId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<GetPlayerBonusQuestionResult>> {
            return PlayerBonusQuestionApiFp(configuration).getBonusQuestion(bonusQuestionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Confirm bonus question as played by player
         * @param {PlayBonusQuestionRequest} body 
         * @param {string} bonusQuestionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async playBonusQuestion(body: PlayBonusQuestionRequest, bonusQuestionId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return PlayerBonusQuestionApiFp(configuration).playBonusQuestion(body, bonusQuestionId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PlayerBonusQuestionApi - object-oriented interface
 * @export
 * @class PlayerBonusQuestionApi
 * @extends {BaseAPI}
 */
export class PlayerBonusQuestionApi extends BaseAPI {
    /**
     * 
     * @summary Get bonus question detail
     * @param {string} bonusQuestionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerBonusQuestionApi
     */
    public async getBonusQuestion(bonusQuestionId: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<GetPlayerBonusQuestionResult>> {
        return PlayerBonusQuestionApiFp(this.configuration).getBonusQuestion(bonusQuestionId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Confirm bonus question as played by player
     * @param {PlayBonusQuestionRequest} body 
     * @param {string} bonusQuestionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerBonusQuestionApi
     */
    public async playBonusQuestion(body: PlayBonusQuestionRequest, bonusQuestionId: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return PlayerBonusQuestionApiFp(this.configuration).playBonusQuestion(body, bonusQuestionId, options).then((request) => request(this.axios, this.basePath));
    }
}
